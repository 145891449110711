import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import axios from '../api'; // Axios instance for API calls

function Showcases() {
  const [showcases, setShowcases] = useState([]);
  const [sortOrder, setSortOrder] = useState('Newest'); // Default sort order

  useEffect(() => {
    const fetchShowcases = async () => {
      try {
        const response = await axios.get('/showcases'); // Call the new API endpoint
        setShowcases(response.data);
      } catch (error) {
        console.error('Error fetching showcases:', error);
      }
    };

    fetchShowcases();
  }, []);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    // Sort the showcases on the front end
    const sortedShowcases = [...showcases].sort((a, b) => {
      if (event.target.value === 'Newest') {
        return new Date(b.created_at) - new Date(a.created_at); // Sort by newest
      } else {
        return new Date(a.created_at) - new Date(b.created_at); // Sort by oldest
      }
    });
    setShowcases(sortedShowcases);
  };

  if (!showcases.length) {
    return <Typography>Loading showcases...</Typography>; // Display a loading state
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography
          variant="h4"
          style={{ textAlign: 'left', color: '#452f0f', fontWeight: 'bold' }}
        >
          Showcases
        </Typography>
        <FormControl variant="outlined" size="small">
          <InputLabel id="sort-label">Sort By</InputLabel>
          <Select
            labelId="sort-label"
            id="sort-select"
            value={sortOrder}
            onChange={handleSortChange}
            label="Sort By"
          >
            <MenuItem value="Newest">Newest</MenuItem>
            <MenuItem value="Oldest">Oldest</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        {showcases.map((showcase) => (
          <Grid item xs={12} md={6} lg={4} key={showcase.showcaseid}>
            <Card>
              <iframe
                title={showcase.title}
                src={showcase.video_url}
                style={{ width: '100%', height: '200px', border: 'none' }}
                allowFullScreen
              ></iframe>
              <CardContent>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                  {showcase.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                  {showcase.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                  <strong>Outfitter:</strong> {showcase.outfitter_name}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                  <strong>Date:</strong> {new Date(showcase.created_at).toLocaleDateString()}
                </Typography>
                {showcase.outings.length > 0 && (
                  <Box>
                    <Typography variant="body2" color="textSecondary" style={{ fontWeight: 'bold' }}>
                      Outings:
                    </Typography>
                    {showcase.outings.map((outing) => (
                      <Button
                        key={outing.outingid}
                        variant="text"
                        color="primary"
                        href={`/outing?outingid=${outing.outingid}`}
                        style={{ textTransform: 'none', margin: '5px 0' }}
                      >
                        {outing.outing_name}
                      </Button>
                    ))}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Showcases;
