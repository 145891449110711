import React, { useState, useEffect } from 'react';
import TemporaryDrawer from '../components/FilterBar';
import SearchCard from '../components/SearchCard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SportMap from '../components/SportMap';
import api from '../api'; // Import the API module

function Search() {
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState({
    category: null,
    attributeid: null,
    attributeValue: '',
    location: null,
    duration: null,
    numPeople: null,
    maxPrice: null,
    activity_id: null,
  });

  // Extract category and attributeid from URL params on page load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('category');
    const attributeid = urlParams.get('attributeid');

    setFilters((prevFilters) => ({
      ...prevFilters,
      category,
      attributeid,
    }));

    // Fetch results with the initial filters
    fetchSearchResults({ category, attributeid });
  }, []);

  // Fetch search results based on filters
  const fetchSearchResults = async (updatedFilters = filters) => {
    try {
      const requestParams = {
        category: updatedFilters.category,
        attributeid: updatedFilters.attributeid,
        ...(updatedFilters.duration && { duration: updatedFilters.duration }),
        ...(updatedFilters.maxPrice && { price_upper: updatedFilters.maxPrice }),
        ...(updatedFilters.numPeople && { num_people: updatedFilters.numPeople }),
        ...(updatedFilters.location && { location: updatedFilters.location }),
        ...(updatedFilters.activity_id && { activity_id: updatedFilters.activity_id }),
      };


      const response = await api.get('/search', { params: requestParams });

      setResults(response.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // Update filters and fetch results
  const applyNewFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
    fetchSearchResults({ ...filters, ...newFilters });
  };

  return (
    <div>
      <TemporaryDrawer setFilters={applyNewFilters} />
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: '3vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {results.length > 0 ? (
              results.map((result) => (
                <SearchCard
                  id={result.outingid}
                  title={result.name}
                  description={result.description}
                  location={result.commonlocation_name}
                  activity={result.activities?.map((activity) => activity.name).join(', ')}
                  rating={result.rating}
                  price={result.price}
                  images={result.images?.map((img) => ({ src: img.url })) || []}
                />
              ))
            ) : (
              <p style={{ textAlign: 'center', marginTop: '20px' }}>
                Sorry we're new! We have no outfitters that meet that criteria yet.
              </p>
            )}
          </Grid>
          <Grid item md={4}>
            <Box
              display={{ xs: 'none', md: 'block' }}
              style={{
                position: 'fixed',
              }}
            >
              <SportMap results={results} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}


export default Search;
