import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from "../media/OutfitterFinderLogo-green-small.png";
import { fetchAuthSession, signIn, signOut } from '@aws-amplify/auth';
import { useMediaQuery, useTheme } from '@mui/material';

const pages = [
  { display: 'Showcases', url: "/showcases" },
  { display: 'Merch', url: "https://outfitterfinder.myspreadshop.com/" },
  { display: 'About Us', url: "/aboutus" },
];

const ResponsiveAppBar = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check for small screens
  useEffect(() => {
    const checkUser = async () => {
      try {
        const session = await fetchAuthSession();
        console.log('Session:', session);

        const idTokenPayload = session?.tokens?.idToken?.payload;

        if (idTokenPayload) {
          setUser(idTokenPayload);
          setUsername(idTokenPayload['cognito:username'] || idTokenPayload['email'] || 'User');
          setUserGroups(idTokenPayload['cognito:groups'] || []);
        } else {
          setUser(null);
          setUsername('');
          setUserGroups([]);
        }
      } catch (error) {
        console.error('Error fetching user session:', error);
        setUser(null);
        setUsername('');
        setUserGroups([]);
      }
    };
    checkUser();
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };



  const handleLogout = async () => {
    try {
      await signOut();
      setUser(null);
      setUsername('');
      setUserGroups([]);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AppBar position="static" style={{ background: "#F9F6F0", maxWidth: "100vw" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          {/* Left-Side Logo and Pages */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to="/">
              <Box
                component="img"
                src={logo}
                alt="OutFitterFinder"
                sx={{
                  maxHeight: 167,
                  maxWidth: 150,
                  mr: 2,
                }}
              />
            </Link>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                page.display === "Merch" ? (
                  <a
                    href={page.url}
                    key={page.display}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#453318', marginLeft: '1rem' }}
                  >
                    <Button
                      sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: '#453318',
                        '&:hover': {
                          color: '#3c2916',
                        },
                      }}
                    >
                      {page.display}
                    </Button>
                  </a>
                ) : (
                  <Link
                    to={page.url}
                    key={page.display}
                    style={{ textDecoration: 'none', color: '#453318', marginLeft: '1rem' }}
                  >
                    <Button
                      sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: '#453318',
                        '&:hover': {
                          color: '#3c2916',
                        },
                      }}
                    >
                      {page.display}
                    </Button>
                  </Link>
                )
              ))}
            </Box>
            </Box>
          </Box>

          {/* Dropdown Menu */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} color="success">
                <PersonIcon />
              </IconButton>
            </Tooltip>
            <Menu
              disablePortal // Prevents menu from being rendered outside the AppBar
              disableScrollLock // Prevents the body from being locked and removes the backdrop
              sx={{
                mt: '45px',
                maxHeight: '90vh',
                width: { xs: '100%', sm: '300px' }, // Ensures no overflow
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* Only Include Pages in Dropdown for Mobile View */}
              {isMobile && pages.map((page) => (
              page.display === "Merch" ? (
                <MenuItem
                  key={page.display}
                  onClick={handleCloseUserMenu}
                  component="a"
                  href={page.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none', color: '#453318' }}
                >
                  <Typography textAlign="center">{page.display}</Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  key={page.display}
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to={page.url}
                  sx={{ textDecoration: 'none', color: '#453318' }}
                >
                  <Typography textAlign="center">{page.display}</Typography>
                </MenuItem>
              )
            ))}
                          {/* Dropdown Logic for Logged-Out Users */}
              {!user && (
                <Link to="/login" style={{ textDecoration: 'none', color: '#453318' }}>
                <MenuItem>
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
                </Link>
              )}
              {!userGroups.includes('outfitter') && (
                <MenuItem onClick={handleCloseUserMenu} component={Link} to="/become-an-outfitter">
                  <Typography textAlign="center">Become an Outfitter</Typography>
                </MenuItem>
              )}

              {/* Dropdown Logic for Logged-In Users */}
              {user && (
                <>
                  <MenuItem onClick={handleCloseUserMenu} component={Link} to="/chats">
                    <Typography textAlign="center">Chats</Typography>
                  </MenuItem>
                  {userGroups.includes('outfitter') && (
                    <MenuItem
                      onClick={handleCloseUserMenu}
                      component={Link}
                      to={`/outfitter?username=${username}`}
                    >
                      <Typography textAlign="center">Outfitter</Typography>
                    </MenuItem>
                  )}
                  {userGroups.includes('admin') && (
                    <MenuItem onClick={handleCloseUserMenu} component={Link} to="/admin">
                      <Typography textAlign="center">Admin</Typography>
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </>
              )}
            </Menu>

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
