import React, { useState, useEffect } from 'react';
import axios from '../api';
import { Box, TextField, Typography, Grid, IconButton, Autocomplete, Chip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const AttributesBreakdown = ({ categoryId, onAttributesChange, initialAttributes = [] }) => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [newAttributeKey, setNewAttributeKey] = useState('');
  const [newAttributeValue, setNewAttributeValue] = useState('');
  const [attributes, setAttributes] = useState(initialAttributes || []);

  // Fetch attributes based on the category
  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await axios.get('/common_attributes', {
          params: { categoryId },
        });
        setAttributeOptions(response.data);
      } catch (error) {
        console.error('Error fetching attributes:', error);
      }
    };

    if (categoryId) {
      fetchAttributes();
    }
  }, [categoryId]);

  // Sync attributes with initialAttributes on first load
  useEffect(() => {
    if (!attributes.length && initialAttributes.length) {
      setAttributes(initialAttributes);
    }
  }, [initialAttributes]);

  // Notify parent of changes
  useEffect(() => {
    onAttributesChange(attributes);
  }, [attributes, onAttributesChange]);

  const handleAddAttribute = () => {
    if (!newAttributeKey || !newAttributeValue) return;

    const updatedAttributes = [...attributes, { key: newAttributeKey, value: newAttributeValue }];
    setAttributes(updatedAttributes);

    if (!attributeOptions.includes(newAttributeKey)) {
      setCustomAttributes((prev) => [...prev, newAttributeKey]);
    }

    setNewAttributeKey('');
    setNewAttributeValue('');
  };

  const handleRemoveAttribute = (key) => {
    const updatedAttributes = attributes.filter((attr) => attr.key !== key);
    setAttributes(updatedAttributes);

    if (customAttributes.includes(key)) {
      setCustomAttributes((prev) => prev.filter((customKey) => customKey !== key));
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Attributes Breakdown
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <Autocomplete
            freeSolo
            options={attributeOptions}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.key || '')}
            value={newAttributeKey}
            onInputChange={(e, value) => setNewAttributeKey(value)}
            renderInput={(params) => (
              <TextField {...params} label="Attribute Key" placeholder="Select or add custom key" />
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Attribute Value"
            value={newAttributeValue}
            onChange={(e) => setNewAttributeValue(e.target.value)}
            placeholder="Enter value"
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton color="primary" onClick={handleAddAttribute}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {attributes.map((attr, index) => (
          <Chip
            key={index}
            label={`${attr.key}: ${attr.value}`}
            onDelete={() => handleRemoveAttribute(attr.key)}
            style={{ backgroundColor: '#6F5B3E', color: '#fff' }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AttributesBreakdown;
