import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
  Card,
  CardMedia,
  CardActions,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ImageUploader = ({ initialImages = [], onImagesChange }) => {
  const [images, setImages] = useState([]);

  /** ✅ Load initial images and maintain order */
  useEffect(() => {
    console.log('Initial images:', initialImages);
    if (initialImages.length > 0) {
      const formattedImages = initialImages.map((img, index) => ({
        file: null, // Not a new upload
        url: typeof img === "string" ? img : img.url, // ✅ Ensure correct URL extraction
        index,
        name: typeof img === "string" ? img.split('/').pop() : img.name, // ✅ Extract filename
        isNew: false, // ✅ Tracks if it's an existing image
      }));

      setImages(formattedImages);
      console.log('Loaded initial images:', formattedImages);
    }
  }, [initialImages]);

  /** ✅ Compute the final state and notify the parent */
  const notifyChanges = (updatedImages) => {
    const sqlData = updatedImages.map((img) => img.name); // ✅ Just file names in order

    const existingFileNames = (Array.isArray(initialImages) ? initialImages : []).map(img =>
      typeof img === "string" ? img.split('/').pop() : img.name
    );

    const deleteFiles = existingFileNames.filter(name => 
      name && !updatedImages.some(img => img.name === name) // ✅ If removed from the list, mark for deletion
    );

    const uploadFiles = updatedImages
      .filter(img => img.file) // ✅ Only new uploads
      .map(img => img.file);

    onImagesChange({ sql: sqlData, delete: deleteFiles, upload: uploadFiles });
  };

  /** ✅ Handle file uploads */
  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files) {
      const uploadedImages = Array.from(files).map((file, index) => ({
        file,
        url: URL.createObjectURL(file), // Temporary preview
        index: images.length + index,
        name: file.name,
        isNew: true, // ✅ Mark as new upload
      }));

      setImages((prevImages) => {
        const updatedImages = [...prevImages, ...uploadedImages].slice(0, 5); // Max 5 images
        notifyChanges(updatedImages);
        return updatedImages;
      });

      console.log('Uploaded images:', uploadedImages);
    }
  };

  /** ✅ Handle image deletion */
  const handleDeleteImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);

      // ✅ Update indexes after deletion
      updatedImages.forEach((img, i) => (img.index = i));

      notifyChanges(updatedImages);
      return updatedImages;
    });

    console.log(`Deleting image at index: ${index}`);
  };

  /** ✅ Handle image reordering */
  const handleMoveImage = (index, direction) => {
    setImages((prevImages) => {
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex >= prevImages.length) {
        console.log("Move operation out of bounds.");
        return prevImages;
      }

      console.log(`Moving image from index ${index} to ${newIndex}`);
      const updatedImages = [...prevImages];
      const [movedImage] = updatedImages.splice(index, 1);
      updatedImages.splice(newIndex, 0, movedImage);

      // ✅ Update indexes correctly
      updatedImages.forEach((img, i) => {
        img.index = i;
        img.name = img.file ? img.file.name : img.url?.split("/").pop() || `image-${i}`;
      });

      notifyChanges(updatedImages);
      return updatedImages;
    });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Upload Images (Max 5)
      </Typography>
      <Button
        variant="contained"
        component="label"
        sx={{ backgroundColor: '#FF8C42', color: '#fff', mb: 2 }}
      >
        <AddPhotoAlternateIcon sx={{ mr: 1 }} />
        Upload Images
        <input
          hidden
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageUpload}
        />
      </Button>

      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                height="140"
                image={image.url}
                alt={`Image ${index + 1}`}
              />
              <CardActions>
                <IconButton
                  color="primary"
                  onClick={() => handleMoveImage(index, -1)}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => handleMoveImage(index, 1)}
                  disabled={index === images.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteImage(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ImageUploader;
