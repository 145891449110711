import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import { fetchAuthSession } from "@aws-amplify/auth";

const WEBSOCKET_URL = "wss://j3kzzhy2r7.execute-api.us-east-1.amazonaws.com/prod";

function Chats() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState(null);
  const [chatPartners, setChatPartners] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const wsRef = useRef(null);
  const messagesEndRef = useRef(null);

  // 🔹 Authenticate user and fetch username
  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const session = await fetchAuthSession();
        const user = session.tokens?.idToken?.payload["cognito:username"];
        if (!user) {
          navigate("/login");
        } else {
          setUsername(user);
        }
      } catch (error) {
        console.error("Authentication error:", error);
        navigate("/login");
      }
    };

    authenticateUser();
  }, [navigate]);

  // 🔹 WebSocket Connection
  useEffect(() => {
    const connectWebSocket = async () => {
      if (!username || wsRef.current) return;

      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens?.accessToken?.toString();
        if (!idToken) {
          console.error("Missing access token for WebSocket authentication.");
          return;
        }

        const socket = new WebSocket(`${WEBSOCKET_URL}?token=${encodeURIComponent(idToken)}`);
        wsRef.current = socket;

        socket.onopen = () => {
          console.log("✅ WebSocket connected");
          setTimeout(() => {
            if (wsRef.current?.readyState === WebSocket.OPEN) {
              socket.send(JSON.stringify({ action: "getChatPartners", userId: username }));
              console.log("📤 Requesting chat partners...");
            }
          }, 500);
        };

        socket.onmessage = (event) => {
          try {
            const response = JSON.parse(event.data);
            console.log("📩 Message received:", response);
            if (response.action === "newMessage") {
              if (response.senderId === selectedChat) {
                setMessages((prev) => [...prev, response.data]);
              }
            } else if (response.action === "chatPartners") {
              setChatPartners([...response.partners]);
            } else if (response.action === "messages" && Array.isArray(response.messages)) {
              console.log(`🔹 Messages received for chat with ${selectedChat}:`, response.messages);
              setMessages([...response.messages]);
            }
          } catch (error) {
            console.warn("⚠️ Received non-JSON message from server:", event.data);
          }
        };
        

        socket.onerror = (error) => console.error("❌ WebSocket error:", error);

        socket.onclose = () => {
          console.log("🔴 WebSocket disconnected, attempting to reconnect...");
          wsRef.current = null;
          setTimeout(connectWebSocket, 5000);
        };
      } catch (error) {
        console.error("WebSocket connection error:", error);
      }
    };

    connectWebSocket();

    return () => {
      wsRef.current?.close();
    };
  }, [username]);

  // 🔹 Load Messages when Chat is Selected
  useEffect(() => {
    const chatWith = searchParams.get("chatWith");
    if (chatWith) {
      setSelectedChat(chatWith);
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ action: "getMessages", userId: username, partnerId: chatWith }));
        console.log(`📤 Requesting messages for chat with ${chatWith}`);
      }
    }
  }, [searchParams]);

  // 🔹 Auto-scroll to new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  // 🔹 Automatically update the UI when chat partners change
  useEffect(() => {
    console.log("🔄 Updating chat partner list:", chatPartners);
  }, [chatPartners]);  // ✅ Watches chatPartners for updates


  // 🔹 Select a chat
  const handleSelectChat = (partner) => {
    setSelectedChat(partner);
    navigate(`/chats?chatWith=${partner}`);

    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ action: "getMessages", userId: username, partnerId: partner }));
      console.log(`📤 Requesting messages for chat with ${partner}`);
    }
  };

  // 🔹 Send Message
  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedChat || !wsRef.current) return;

    if (wsRef.current.readyState !== WebSocket.OPEN) {
      console.warn("WebSocket is not open. Message not sent.");
      return;
    }

    const messageData = {
      action: "sendMessage",
      userId: username,
      recipientId: selectedChat,
      message: newMessage,
    };

    wsRef.current.send(JSON.stringify(messageData));

    setMessages((prev) => [
      ...prev,
      { senderId: username, message: newMessage, timestamp: new Date().toISOString() },
    ]);
    setNewMessage("");
  };

  return (
    <Box display="flex" height="80vh">
      {/* 🔹 Chat Partners List */}
      <Paper sx={{ width: "30%", overflowY: "auto", borderRight: "1px solid #ccc" }}>
        <Typography variant="h6" sx={{ textAlign: "center", p: 2 }}>Chats</Typography>
        <List>
          {chatPartners.length > 0 ? (
            chatPartners.map((partner) => (
              <ListItem
                button
                key={partner}
                selected={partner === selectedChat}
                onClick={() => handleSelectChat(partner)}
              >
                <ListItemText primary={partner} />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" sx={{ textAlign: "center", p: 2 }}>No chat partners found</Typography>
          )}
        </List>
      </Paper>

      {/* 🔹 Chat Window */}
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", p: 2 }}>
        {selectedChat ? (
          <>
            <Typography variant="h6" sx={{ borderBottom: "1px solid #ccc", pb: 1 }}>
              Chat with {selectedChat}
            </Typography>
            <Box sx={{ flex: 1, overflowY: "auto", p: 2 }}>
              {messages.length > 0 ? (
                messages.map((msg, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: msg.senderId === username ? "flex-end" : "flex-start",
                      mb: 1,
                    }}
                  >
                    <Paper sx={{
                      p: 1.5,
                      maxWidth: "70%",
                      bgcolor: msg.senderId === username ? "#FF8C42" : "#E0E0E0",
                      color: msg.senderId === username ? "white" : "black",
                      borderRadius: "10px",
                    }}>
                      <Typography variant="body1">{msg.message}</Typography>
                      <Typography variant="caption" sx={{ display: "block", textAlign: "right", mt: 0.5 }}>
                        {new Date(msg.timestamp).toLocaleTimeString()}
                      </Typography>
                    </Paper>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" sx={{ textAlign: "center", mt: 4 }}>No messages yet</Typography>
              )}
              <div ref={messagesEndRef} />
            </Box>

            {/* 🔹 Message Input */}
            <Box sx={{ display: "flex", p: 2, borderTop: "1px solid #ccc" }}>
              <TextField fullWidth value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder="Type a message..." />
              <Button variant="contained" sx={{ ml: 2, backgroundColor: "#FF8C42" }} onClick={handleSendMessage}>Send</Button>
            </Box>
          </>
        ) : (
          <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>Select a chat to start messaging</Typography>
        )}
      </Box>
    </Box>
  );
}

export default Chats;
