import { Typography, Grid, Card, CardContent, Container, Avatar, Tabs, Tab, Box, CircularProgress } from '@mui/material';
import HikingIcon from '@mui/icons-material/Hiking';
import PicSlide from '../components/PicSlide';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../api';
import Description from '../components/outingTabs/Description';
import Included from '../components/outingTabs/Included';
import Cancelation from '../components/outingTabs/Cancelation';
import RatingComp from '../components/outingTabs/RatingComp';
import OutfitterDetails from '../components/OutfitterOutingCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Outing() {
  const query = useQuery();
  const outingid = query.get('outingid');
  const [tabValue, setTabValue] = useState(0);
  const [outfitterDetails, setOutfitterDetails] = useState(null);
  const [outingDetails, setOutingDetails] = useState(null); // Change to null initially
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchOutingDetails = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get('/outing', { params: { outingid } });
        const outfitter = await axios.get('/outfitter_details', { params: { outfitterid: response.data[0].outfitterid } });
        setOutingDetails(response.data[0]); // Adjust this based on API response structure
        setOutfitterDetails(outfitter.data[0]);
        console.log(outingDetails);
      } catch (error) {
        console.error('Error fetching outing details:', error);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    if (outingid) {
      fetchOutingDetails();
    }
  }, [outingid]);

  if (isLoading) {
    return (
      <Container>
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  if (!outingDetails) {
    return (
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: '2rem' }}>
        Outing details not found.
      </Typography>
    );
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardContent>
            <Typography
              variant="h5"
              style={{ textAlign: 'left', marginTop: '1vh', fontWeight: 'bold' }}
              gutterBottom
            >
              {outingDetails.name}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ textAlign: 'left', color: 'var(--of-brown)' }}
              gutterBottom
            >
              <HikingIcon style={{ fontSize: 'large', margin: 'auto' }} />
              {outingDetails.commonLocation} | {outingDetails.activity.activityName} |{' '}
              {outingDetails.duration} | Max Participants: {outingDetails.maxParticipants}
            </Typography>
          </CardContent>
        </Grid>

        <Grid item xs={12} sm={6}>
          <PicSlide
            imageData={outingDetails.images.map((img) => ({ src: img.url }))}
          />
        </Grid>

        {outfitterDetails && <OutfitterDetails outfitterDetails={outfitterDetails} />}

        <Grid item xs={12}>
          <Card raised>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab label="Description" {...a11yProps(0)} style={{ color: 'var(--of-orange)' }} />
              <Tab
                label="Included/Excluded"
                {...a11yProps(1)}
                style={{ color: 'var(--of-orange)' }}
              />
              <Tab
                label="Cancellation Policy"
                {...a11yProps(2)}
                style={{ color: 'var(--of-orange)' }}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Description description={outingDetails.description} attributes={outingDetails.attributes} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Included inclusions={outingDetails.inex.inclusions} exclusions={outingDetails.inex.exclusions} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Cancelation cancellationPolicy={outingDetails.cancellationPolicy} />
            </TabPanel>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <RatingComp outingid={outingid} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Outing;
