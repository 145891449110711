import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

function Description({ description, attributes }) {
  return (
    <>
      <Typography variant="subtitle1" style={{ marginTop: "1vh", fontWeight: 'bold' }}>
        Service Description:
      </Typography>
      <Typography component="div" style={{ textAlign: "left", marginTop: "1vh" }}>
        {description || "No description available."}
      </Typography>
      <div style={{ maxWidth: "90%", margin: "auto" }}>
        <Typography variant="subtitle1" style={{ marginTop: "1vh", fontWeight: 'bold' }} align="left">
          Breakdown:
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: "100%" }} aria-label="breakdown table">
            <TableBody>
              {attributes && attributes.length > 0 ? (
                attributes.map((attr, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {attr.key}
                    </TableCell>
                    <TableCell align="right">{attr.value}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No breakdown data available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Description;
