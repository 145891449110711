import React, { useState, useEffect } from 'react';
import axios from '../api';
import { Box, TextField, Typography, Button, Chip, Grid, Autocomplete } from '@mui/material';

const InclusionsExclusions = ({
  categoryId,
  initialInclusions = [],
  initialExclusions = [],
  onInclusionsExclusionsChange,
}) => {
  const [inexOptions, setInexOptions] = useState([]);
  const [inclusions, setInclusions] = useState(initialInclusions);
  const [exclusions, setExclusions] = useState(initialExclusions);
  const [newInclusion, setNewInclusion] = useState('');
  const [newExclusion, setNewExclusion] = useState('');

  const colorPalette = {
    background: '#FDF6E3', // Cream
    text: '#6F5B3E', // Brown
    accent: '#FF8C42', // Orange
    chipBackground: '#6F5B3E', // Brown
    chipText: '#FFF', // White
  };

  useEffect(() => {
    const fetchInexOptions = async () => {
      try {
        const response = await axios.get('/common_inex', {
          params: { categoryId },
        });
        setInexOptions(response.data);
      } catch (error) {
        console.error('Error fetching inclusions/exclusions:', error);
      }
    };

    if (categoryId) {
      fetchInexOptions();
    }
  }, [categoryId]);

  useEffect(() => {
    // Sync state with initial values on mount or when props change
    setInclusions(initialInclusions);
    setExclusions(initialExclusions);
  }, [initialInclusions, initialExclusions]);

  const handleAddInclusion = (value) => {
    if (!value) return;

    const updatedInclusions = [...inclusions, value];
    setInclusions(updatedInclusions);
    onInclusionsExclusionsChange({ inclusions: updatedInclusions, exclusions });

    setNewInclusion('');
  };

  const handleAddExclusion = (value) => {
    if (!value) return;

    const updatedExclusions = [...exclusions, value];
    setExclusions(updatedExclusions);
    onInclusionsExclusionsChange({ inclusions, exclusions: updatedExclusions });

    setNewExclusion('');
  };

  const handleRemoveInclusion = (value) => {
    const updatedInclusions = inclusions.filter((item) => item !== value);
    setInclusions(updatedInclusions);
    onInclusionsExclusionsChange({ inclusions: updatedInclusions, exclusions });
  };

  const handleRemoveExclusion = (value) => {
    const updatedExclusions = exclusions.filter((item) => item !== value);
    setExclusions(updatedExclusions);
    onInclusionsExclusionsChange({ inclusions, exclusions: updatedExclusions });
  };

  return (
    <Box sx={{ mt: 3, backgroundColor: colorPalette.background, p: 3, borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>
        Inclusions and Exclusions
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom sx={{ color: colorPalette.text }}>
            Inclusions
          </Typography>
          <Autocomplete
            freeSolo
            options={inexOptions}
            value={newInclusion}
            onInputChange={(e, value) => setNewInclusion(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Inclusion"
                placeholder="Select or add custom inclusion"
                sx={{ backgroundColor: '#fff', borderRadius: 1 }}
              />
            )}
          />
          <Button
            variant="contained"
            sx={{ mt: 1, backgroundColor: colorPalette.accent, color: '#fff' }}
            onClick={() => handleAddInclusion(newInclusion)}
          >
            Add Inclusion
          </Button>
          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {inclusions.map((item, index) => (
              <Chip
                key={index}
                label={item}
                onDelete={() => handleRemoveInclusion(item)}
                sx={{
                  backgroundColor: colorPalette.chipBackground,
                  color: colorPalette.chipText,
                }}
              />
            ))}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom sx={{ color: colorPalette.text }}>
            Exclusions
          </Typography>
          <Autocomplete
            freeSolo
            options={inexOptions}
            value={newExclusion}
            onInputChange={(e, value) => setNewExclusion(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Exclusion"
                placeholder="Select or add custom exclusion"
                sx={{ backgroundColor: '#fff', borderRadius: 1 }}
              />
            )}
          />
          <Button
            variant="contained"
            sx={{ mt: 1, backgroundColor: colorPalette.accent, color: '#fff' }}
            onClick={() => handleAddExclusion(newExclusion)}
          >
            Add Exclusion
          </Button>
          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {exclusions.map((item, index) => (
              <Chip
                key={index}
                label={item}
                onDelete={() => handleRemoveExclusion(item)}
                sx={{
                  backgroundColor: colorPalette.chipBackground,
                  color: colorPalette.chipText,
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InclusionsExclusions;
