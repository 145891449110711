import React, { useState, useEffect } from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import back from '../media/bg9.jpg';
import badge from '../media/badge.png';
import { fetchCategories, fetchSearchValues } from '../api'; // Updated API calls

// Styled IconButton for a custom look
const CustomSearchButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#1b2945',
  borderRadius: '0 4px 4px 0',
  height: '56px',
  width: '56px',
  '&:hover': {
    backgroundColor: '#142038',
  },
  color: 'white',
  padding: '12px',
  marginLeft: '-1px',
}));

export default function HomeCard() {
  const [categories, setCategories] = useState([{ id: '0', name: 'Categories' }]);
  const [selectedCategory, setSelectedCategory] = useState('0');
  const [searchValues, setSearchValues] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedAttribute, setSelectedAttribute] = useState(null); // To store the selected attribute's ID and value
  const navigate = useNavigate();

  // Fetch categories and search values on component mount
  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch categories
        const categoriesData = await fetchCategories();
        const formattedCategories = categoriesData.map(category => ({
          id: category.category_id.toString(),
          name: category.category_name,
        }));
        setCategories([{ id: '0', name: 'Categories' }, ...formattedCategories]);

        // Fetch search values grouped by parent ID
        const searchValuesData = await fetchSearchValues();
        setSearchValues(searchValuesData);
        setSuggestions(Object.values(searchValuesData).flat().map(value => ({ label: value.name, parentid: value.parentid, id: value.id })));
      } catch (error) {
        console.error('Failed to fetch categories or search values', error);
      }
    }

    fetchData();
  }, []);

  // Update autocomplete suggestions when category changes
  useEffect(() => {
    if (selectedCategory === '0') {
      // Show all suggestions when no category is selected
      setSuggestions(Object.values(searchValues).flat().map(value => ({ label: value.name, parentid: value.parentid, id: value.id })));
    } else {
      // Filter suggestions based on the selected category
      setSuggestions(
        (searchValues[selectedCategory] || []).map(value => ({ label: value.name, parentid: value.parentid, id: value.id }))
      );
    }
  }, [selectedCategory, searchValues]);

  // Handle selection of an autocomplete value
  const handleSelection = (_, selectedOption) => {
    if (selectedOption) {
      setSelectedAttribute({ id: selectedOption.id, name: selectedOption.label, parentid: selectedOption.parentid });
    } else {
      setSelectedAttribute(null);
    }
  };

  // Function to handle search
  const handleSearch = () => {
    let categoryForSearch = selectedCategory;

    // Infer category from the selected input if category is '0'
    if (selectedCategory === '0' && selectedAttribute) {
      categoryForSearch = selectedAttribute.parentid.toString();
    }

    const attributeId = selectedAttribute?.id || '';
    const attributeValue = selectedAttribute?.name || inputValue;

    navigate(`/search?category=${categoryForSearch}&attributeid=${attributeId}&attributeValue=${encodeURIComponent(attributeValue)}`);
  };

  return (
    <div style={{
      backgroundImage: `url(${back})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "white"
    }}>
      <div style={{
        width: '300px',
        height: 'auto',
        marginBottom: '20px',
        marginTop: '15vh',
      }}>
        <img src={badge} alt="Choose your own adventure" style={{ width: '100%', height: '100%' }} />
      </div>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "auto", maxWidth: "95vw", backgroundColor: "transparent" }}>
        <Select
          value={selectedCategory}
          onChange={e => setSelectedCategory(e.target.value)}
          style={{ marginRight: "-1px", height: "56px", backgroundColor: "white", borderRadius: '4px 0 0 4px' }}
          MenuProps={{ disableScrollLock: true }}
        >
          {categories.map(category => (
            <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
          ))}
        </Select>
        <Autocomplete
          id="activity-autocomplete"
          options={suggestions}
          getOptionLabel={(option) => option.label || ''}
          style={{ width: 300, backgroundColor: "white", borderRadius: '0' }}
          renderInput={(params) => <TextField {...params} label="Search Activities or Locations" variant="outlined" />}
          onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
          onChange={handleSelection} // Handle selection
          freeSolo
          disableClearable
          MenuProps={{ disableScrollLock: true }}
        />
        <CustomSearchButton aria-label="search" onClick={handleSearch}>
          <SearchIcon />
        </CustomSearchButton>
      </FormControl>
    </div>
  );
}
