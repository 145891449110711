import './App.css';
import ResponsiveAppBar from './components/AppBar'
import Home from './screens/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Search from './screens/Search';
import Outing from './screens/Outing';
import Container from '@mui/material/Container';
import Showcases from './screens/Showcases';
import AboutUs from './screens/AboutUs';
import Login from './screens/Login';
import BecomeAnOutfitter from './screens/BecomeAnOutfitter';
import Outfitter from './screens/Outfitter';
import Chats from './screens/Chats';
import Admin from './screens/Admin';

function App() {
  return (
    <div className="App">
      
      <ResponsiveAppBar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/outing" element={<Outing />} />
        <Route path="/showcases" element={<Showcases />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/login" element={<Login />} />

        <Route path="/become-an-outfitter" element={<BecomeAnOutfitter />} />
        <Route path="/outfitter" element={<Outfitter />} />
        <Route path="/chats" element={<Chats />} />
        <Route path="/admin" element={<Admin />} />

      </Routes>
  
    </div>
  );
}

export default App;
