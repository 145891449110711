import React from 'react';
import HomeCard from '../components/HomeCard';
import RecentOutings from '../components/RecentOutings';

function Home() {
  return (
    <div>
      <HomeCard />
      <RecentOutings />
    </div>
  );
}

export default Home;
