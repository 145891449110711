import React, { useEffect, useState } from 'react';
import { fetchRecentOutings } from '../api'; // Import the new API function
import SearchCard from './SearchCard';
import './css/RecentOutings.css'; // Create a CSS file for styling

function RecentOutings() {
  const [recentOutings, setRecentOutings] = useState([]);

  useEffect(() => {
    // Fetch recent outings when the component mounts
    async function getRecentOutings() {
      try {
        const outings = await fetchRecentOutings();
        setRecentOutings(outings);
      } catch (error) {
        console.error('Error fetching recent outings:', error);
      }
    }

    getRecentOutings();
  }, []);

  return (
    <div className="recent-outings-container">
      <h2>Recent Outings</h2>
      <div className="scrollable-outings">
        {recentOutings.map((outing) => (
          console.log(outing),
          <SearchCard
            id={outing.outingid}
            title={outing.name}
            description={outing.description}
            location={outing.commonlocation_name}
            activity={outing.activities.map(activity => activity.name).join(", ")} // Convert activities array to comma-separated string
            rating={outing.rating}
            price={outing.price}
            images={outing.images.map((img) => ({ src: img.url }))}
          />
        ))}
      </div>
    </div>
  );
}

export default RecentOutings;
