import React, { useState, useEffect } from 'react';
import axios , {submitOuting} from '../api';
import {
  Box,
  TextField,
  Typography,
  Button,
  Chip,
  Grid,
  MenuItem,
  Divider,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  CircularProgress
} from '@mui/material';

import { AddressAutofill } from '@mapbox/search-js-react';
import AttributesBreakdown from './AttributesBreakdown';
import InclusionsExclusions from './InclussionsExclusions';
import ImageUploader from './ImageUploader';
import OutfitterTermsModal from './OutfitterTermsModal';
import { useNavigate } from 'react-router';
import { fetchAuthSession } from '@aws-amplify/auth';

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_TOKEN;

const OutingForm = ({outing, onClose}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [commonActivities, setCommonActivities] = useState([]);
  const [commonLocations, setCommonLocations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [newActivity, setNewActivity] = useState('');
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [errors, setErrors] = useState({ exactLocation: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    outingid: 0,
    name: '',
    description: '',
    category: null,
    activity: [],
    commonLocation: '',
    exactLocation: {
      placeName: '',
      geopoint: [null, null],
    },
    attributes: [],
    cancellationPolicy: {
      refundPercentage: null,
      daysBefore: null,
    },
    legalAgreement: false,
    images: [],
    maxParticipants: null,
    price: null,
    duration: null,
    inex: {
      inclusions: [],
      exclusions: [],
    },
  });

  const handleCancel = ()=>{
   setFormData({
      outingid: 0,
      name: '',
      description: '',
      category: null,
      activity: [],
      commonLocation: '',
      exactLocation: {
        placeName: '',
        geopoint: [null, null],
      },
      attributes: [],
      cancellationPolicy: {
        refundPercentage: null,
        daysBefore: null,
      },
      legalAgreement: false,
      images: [],
      maxParticipants: null,
      price: null,
      duration: null,
      inex: {
        inclusions: [],
        exclusions: [],
      },
    });
    onClose();
  }

  

  const navigate = useNavigate();
  useEffect(() => {
    const fetchCommonData = async () => {
      try {
        const categoriesResponse = await axios.get('/categories');
        categoriesResponse.data.shift(); // Remove the 'locations' category
        setCategories(categoriesResponse.data);
  
        const locationsResponse = await axios.get('/common_locations');
        setCommonLocations(locationsResponse.data);
  
        const session = await fetchAuthSession();
        const username = session?.tokens?.idToken?.payload['cognito:username'];
        setUsername(username);
      } catch (error) {
        console.error('Error fetching common data:', error);
      }
    };
  
    fetchCommonData();
  }, []); // Only runs once on mount
  
  // New useEffect to update form when outing changes
  useEffect(() => {
    console.log("OUTING_", outing)
    if (outing) {
      setFormData({
        outingid: outing.outingid || 0,
        name: outing.name || '',
        description: outing.description || '',
        category: outing.category || null, // Ensure correct key
        activity: outing.activities || [], // Ensure correct key
        commonLocation: outing.commonlocation || '', // Map correctly
        exactLocation: {
          placeName: outing.exactlocation_placename || '', // Map correctly
          geopoint: [outing.exactlocation_longitude, outing.exactlocation_latitude]|| [null, null], // Map correctly
        },
        attributes: outing.attributes || [],
        cancellationPolicy: {
          refundPercentage: outing.cancellationpolicy.refundPercentage ||null,
          daysBefore: outing.cancellationpolicy.daysBefore ||null 
        },
        legalAgreement: outing.legalagreement || false,
        images: outing.images || [], // Handle images correctly
        maxParticipants: outing.maxparticipants || null,
        price: outing.price || null,
        duration: outing.duration || null,
        inex: {
          inclusions: outing.inex.inclusions || [],
          exclusions:  outing.inex.exclusions || [],
        },
      });
  
      setSelectedCategory(outing.category || '');
      setActivities(outing.activities || []);
    }
  }, [outing]); // ✅ Runs whenever outing changes
  
  const handleCategoryChange = async (categoryId) => {
    setSelectedCategory(categoryId);
    setFormData((prev) => ({ ...prev, category: categoryId, activity: [] }));

    try {
      const response = await axios.get('/common_activities', { params: { categoryId } });
      setCommonActivities(response.data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handleAddActivity = () => {
    if (!newActivity || activities.includes(newActivity)) return; // Avoid duplicates
  
    const updatedActivities = [...activities, newActivity];
    setActivities(updatedActivities);
    setFormData((prev) => ({ ...prev, activity: updatedActivities }));
    setNewActivity(''); // Reset the input
  };
  
  const handleRemoveActivity = (index) => {
    const updatedActivities = activities.filter((_, i) => i !== index); // Use index directly
    setActivities(updatedActivities);
    setFormData((prev) => ({ ...prev, activity: updatedActivities }));
  };
  
  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!legalAgreement) {
      alert('You must agree to the Terms and Conditions before submitting.');
      return;
    }
  
    setIsLoading(true);
    setErrorMessage(null); // Clear any previous error messages
  
    const response = await submitOuting(formData);
    
    setIsLoading(false);
  
    onClose();
  }

  return (
    <Box
      sx={{
        maxWidth: '90vw',
        margin: 'auto',
        mt: 4,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: '#FDF6E3',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Create Outing

        <Button
        variant="outlined"
        color="primary"
        sx={{ float: 'right', mb: 2 }}
        onClick={handleCancel} // Close the form
      >
        Cancel
      </Button>

      </Typography>
            {/* Close Button */}

      <Divider sx={{ my: 3, borderColor: '#FF8C42' }} />




      <Grid container spacing={3}>
        {/* Outing Name */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Outing Name"
            value={formData.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
          />
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={formData.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
          />
        </Grid>

        {/* Category */}
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Category"
            value={selectedCategory}
            onChange={(e) => handleCategoryChange(e.target.value)}
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
          >
            {categories?.length > 0 ? categories.map((category) => (
              <MenuItem key={category.category_id} value={category.category_id}>
                {category.category_name}
              </MenuItem>
            )) : (
              <MenuItem disabled value="">
                Loading Categories...
              </MenuItem>
            )}
          </TextField>
        </Grid>

  
      {/* Activities */}


      <Grid item xs={12}>
  <Typography variant="h6" gutterBottom>
    Activities
  </Typography>
  <Grid container spacing={2} alignItems="center">
    <Grid item xs={10}>
      <Autocomplete
        options={commonActivities} // Ensure commonActivities is an array of strings
        freeSolo
        value={newActivity}
        onInputChange={(e, value) => setNewActivity(value)} // Track input changes
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Activity"
            placeholder="Select or add custom activity"
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
          />
        )}
      />
    </Grid>
    <Grid item xs={2}>
      <Button
        variant="contained"
        onClick={handleAddActivity}
        sx={{ backgroundColor: '#FF8C42', color: '#fff' }}
      >
        Add
      </Button>
    </Grid>
  </Grid>
  <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    {activities.map((activity, index) => (
      <Chip
        key={`${activity}-${index}`} // Use activity name and index to ensure unique keys
        label={activity} // Pass a string as the label
        onDelete={() => handleRemoveActivity(index)}
        style={{ backgroundColor: '#6F5B3E', color: '#fff' }}
        />
    ))}
  </Box>
</Grid>


  {/* Common Location */}

  <Grid item xs={12}>
  <TextField
    select
    fullWidth
    label="Common Location"
    helperText="Common Locations are used for map search"
    value={formData.commonLocation || ''} // Ensure a controlled component
    onChange={(e) => {
      const newValue = e.target.value;
      if (formData.commonLocation !== newValue) {
        handleInputChange('commonLocation', newValue); // Update only if different
      }
    }}
    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
    SelectProps={{
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: 300, // Limits dropdown height
            width: 'auto', // Prevents full-width menu
          },
        },
      },
    }}
  >
    {/* Prevents errors if commonLocations is undefined/null */}
    {commonLocations?.length > 0 ? (
      commonLocations.map((location, index) => (
        <MenuItem key={index} value={location || ''}>
          {location || ""}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled value="">
        Loading locations...
      </MenuItem>
    )}
  </TextField>
</Grid>





      {/* Exact Location */}
      <Grid item xs={12}>
        <AddressAutofill
          accessToken={MAPBOX_API_KEY}
          onRetrieve={(result) => {
            const placeName = result.features?.[0]?.properties?.full_address || '';
            const geopoint = result.features?.[0]?.geometry?.coordinates || [];
            setFormData((prevData) => ({
              ...prevData,
              exactLocation: { ...prevData.exactLocation, placeName, geopoint },
            }));
            setErrors((prevErrors) => ({
              ...prevErrors,
              exactLocation: '', // Clear any previous address errors
            }));
          }}
        >
          <TextField
            label="Exact Address"
            fullWidth
            value={formData.exactLocation.placeName || ''} // Ensure value is always a string
            onChange={(e) => {
              const placeName = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                exactLocation: { ...prevData.exactLocation, placeName },
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                exactLocation: '', // Clear errors on input
              }));
            }}
            error={!!errors.exactLocation}
            helperText={errors.exactLocation}
            InputLabelProps={{
              shrink: true, // Keeps the label above the field
            }}
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
          />
        </AddressAutofill>
      </Grid>



  <Box
      sx={{
        mt: 3,
        p: 3,

      }}
    >
  <Typography variant="h6" sx={{ mb: 2, color: '#6F5B3E' }}>
    Outing Details
  </Typography>
  <Grid container spacing={3}>
    {/* Max Participants */}
    <Grid item xs={12} md={4}>
      <TextField
        fullWidth
        label="Max Participants"
        type="number"
        value={formData.maxParticipants}
        onChange={(e) => handleInputChange('maxParticipants', e.target.value)}
        sx={{
          backgroundColor: '#fff',
          borderRadius: 1,
        }}
        InputProps={{
          sx: { padding: '10px', fontSize: '14px' },
        }}
      />
    </Grid>

    {/* Price */}
    <Grid item xs={12} md={4}>
      <TextField
        fullWidth
        label="Price"
        type="number"
        value={formData.price}
        onChange={(e) => handleInputChange('price', e.target.value)}
        sx={{
          backgroundColor: '#fff',
          borderRadius: 1,
        }}
        InputProps={{
          sx: { padding: '10px', fontSize: '14px' },
        }}
      />
    </Grid>

    {/* Duration */}
    <Grid item xs={12} md={4}>
      <TextField
        fullWidth
        label="Duration (days)"
        type="number"
        value={formData.duration}
        onChange={(e) => handleInputChange('duration', e.target.value)}
        sx={{
          backgroundColor: '#fff',
          borderRadius: 1,
        }}
        InputProps={{
          sx: { padding: '10px', fontSize: '14px' },
        }}
      />
    </Grid>
  </Grid>
</Box>

  <Box
    sx={{
      mt: 3,
      p: 3,

    }} >
    <Grid container spacing={3}>
      <Grid item xs={12}>

        <Typography variant="h6" gutterBottom>
          Cancellation Policy
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Refund Percentage"
          type="number"
          value={formData.cancellationPolicy.refundPercentage}
          onChange={(e) =>
            handleInputChange('cancellationPolicy', {
              ...formData.cancellationPolicy,
              refundPercentage: e.target.value,
            })
          }
          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Days Before"
          type="number"
          value={formData.cancellationPolicy.daysBefore}
          onChange={(e) =>
            handleInputChange('cancellationPolicy', {
              ...formData.cancellationPolicy,
              daysBefore: e.target.value,
            })
          }
          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
        />
      </Grid>
    </Grid>
  </Box>

        {/* Additional Components */}
        <Grid item xs={12}>
          <AttributesBreakdown
            initialAttributes={formData.attributes || []}
            categoryId={selectedCategory}
            onAttributesChange={(updatedAttributes) => handleInputChange('attributes', updatedAttributes)}
          />
        </Grid>
        <Grid item xs={12}>
          <InclusionsExclusions
            categoryId={selectedCategory}
            onInclusionsExclusionsChange={(data) => handleInputChange('inex', data)}
            initialInclusions = {formData.inex.inclusions || []}
            initialExclusions = {formData.inex.exclusions || []}
          />
        </Grid>
        <Grid item xs={12}>
        <ImageUploader 
            initialImages={formData.images || []} 
            onImagesChange={(updatedImages) => 
              handleInputChange('images', updatedImages)
            } 
          />
        </Grid>
      </Grid>

      <Box sx={{ p: 3 }}>
          {/* Terms and Conditions */}
          <FormControlLabel
            control={
              <Checkbox
                checked={legalAgreement}
                onChange={(e) => {
                  setLegalAgreement(e.target.checked)
                  formData.legalAgreement = e.target.checked
                }}
              />
            }
            label={
              <Typography variant="body2">
                I agree to the{' '}
                <span
                  style={{ color: '#FF8C42', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => setTermsOpen(true)}
                >
                  Terms and Conditions
                </span>
              </Typography>
            }
          />
          {/* Submit Button */}
          <Button
            variant="contained"
            sx={{ backgroundColor: '#FF8C42', color: '#fff', mt: 3 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>

          {isLoading && <CircularProgress sx={{ mt: 2 }} />}
          {errorMessage && (
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}

          {/* Terms Modal */}
          <OutfitterTermsModal open={termsOpen} onClose={() => setTermsOpen(false)} />
        </Box>

          </Box>

          
    
  );
};

export default OutingForm;
