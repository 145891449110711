import React from 'react';
import Card from '@mui/material/Card';
import PicSlide from './PicSlide';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import PublicIcon from '@mui/icons-material/Public';
import HikingIcon from '@mui/icons-material/Hiking';
import BoltIcon from '@mui/icons-material/Bolt';
import Rating from '@mui/material/Rating';
import { Link } from "react-router-dom";
export default function SearchCard({ id, title, description, location, activity, rating, price, images }) {
  return (
    <Card style={{ margin: "auto" }} sx={{ maxWidth: "60vw" }}>
      <Grid container spacing={2} style={{ margin: "auto" }} sx={{ maxWidth: "60vw" }}>
        <Grid xs={12} md={4}>
          <PicSlide viewHeight="20vh" viewWidth="100%" imageData={images} />
        </Grid>
        <Grid xs={12} md={8}>
          <Link to={`/outing?outingid=${id}`} style={ {textDecoration: 'none', color: '#6F5B3E' }}>
          <h5 style={{ margin: "1vh 0" }}>{title}</h5>
          </Link>
          <Divider />
          <p style={{ fontSize: ".6rem" }}>{description}</p>
          <Divider />
          <Grid container spacing={0.5}>
            <Grid xs={6} md={6}>
            <div style={{ marginTop: '1vh', display: 'flex', alignItems: 'center' }}>
            <PublicIcon style={{ fontSize: "medium", marginLeft: "1vw" }} />
            <span style={{ fontSize: ".6rem" }}>{location}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HikingIcon style={{ fontSize: "medium", marginLeft: "1vw" }} />
            <span style={{ fontSize: ".6rem", whiteSpace: 'normal', wordBreak: 'break-word' }}>{activity}</span>
          </div>


            </Grid>
            <Grid xs={6} md={6}>
              <span style={{ fontSize: ".6rem" }}>Packages as low as:</span>
              <div style={{ fontSize: ".8rem" }}>${price}</div>
              <Rating name="size-small" defaultValue={rating} size="small" readOnly />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
