import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stepper, Step, StepLabel, Button, TextField, Typography, Grid, Paper, Avatar, IconButton } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession } from '@aws-amplify/auth';
import { submitOutfitter } from '../api';
import { AddressAutofill } from '@mapbox/search-js-react';

const steps = ['Outfitter Details', 'Social Media Links', 'Upload Photo'];

const BecomeAnOutfitter = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    outfitterName: '',
    ownerName: '',
    description: '',
    address: '',
    website: '',
    email: '',
    phoneNumber: '',
    instagram: '',
    twitter: '',
    facebook: '',
    photo: null,
  });
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const MAPBOX_API_KEY = 'pk.eyJ1Ijoiam9zZXBobGV2YSIsImEiOiJjbDh4dzAwb3AwYW92M29ud2JrZnBhYTU1In0.pKlCRuU-CQvqyC92ZzU9Ew';

  useEffect(() => {
    const checkSessionAndUser = async () => {
      try {
        const session = await fetchAuthSession();
        const idTokenPayload = session?.tokens?.idToken?.payload;
        if (idTokenPayload) {
          setUser({
            username: idTokenPayload['cognito:username'],
            email: idTokenPayload.email,
          });
        }
      } catch (error) {
        console.error('No valid session or user:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkSessionAndUser();
  }, []);

  const handleNext = async () => {
    if (validateStep()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleInputChange = (field, value) => setFormData((prevData) => ({ ...prevData, [field]: value }));

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({ ...prevData, photo: file }));
  };

  const validateStep = () => {
    const currentErrors = {};

    if (activeStep === 0) {
      if (!formData.outfitterName) currentErrors.outfitterName = 'Outfitter name is required.';
      if (!formData.ownerName) currentErrors.ownerName = 'Owner name is required.';
      if (!formData.description) currentErrors.description = 'Description is required.';
      if (!formData.address) currentErrors.address = 'Address is required.';

      if (!formData.email) {
        currentErrors.email = 'Email is required.';
      } else if (!validateEmail(formData.email)) {
        currentErrors.email = 'Invalid email address. Please enter a valid email.';
      }

      if (!formData.phoneNumber) {
        currentErrors.phoneNumber = 'Phone number is required.';
      } else if (!validatePhoneNumber(formData.phoneNumber)) {
        currentErrors.phoneNumber = 'Invalid phone number. Please use a valid format.';
      }
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      await submitOutfitter(formData);
      navigate(`/outfitter/${user.username}`);
    } catch (error) {
      console.error('Error submitting outfitter:', error);
      alert('Failed to create outfitter. Please try again.');
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Outfitter Name"
                fullWidth
                value={formData.outfitterName}
                onChange={(e) => handleInputChange('outfitterName', e.target.value)}
                error={!!errors.outfitterName}
                helperText={errors.outfitterName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Owner Name"
                fullWidth
                value={formData.ownerName}
                onChange={(e) => handleInputChange('ownerName', e.target.value)}
                error={!!errors.ownerName}
                helperText={errors.ownerName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                multiline
                rows={4}
                fullWidth
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressAutofill
                accessToken={MAPBOX_API_KEY}
                onRetrieve={(result) => {
                  const placeName = result.features?.[0]?.properties?.full_address || '';
                  setFormData((prevData) => ({
                    ...prevData,
                    address: placeName,
                  }));
                  handleInputChange('address', placeName)
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    address: '', // Clear any previous address errors
                  }));
                }}
              >
                <TextField
                  label="Full Address"
                  fullWidth
                  value={formData.address || ''} // Ensure the value is always a string
                  onChange={(e) => {
                    handleInputChange('address', e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      address: '', // Clear errors on input
                    }));
                  }}
                  error={!!errors.address}
                  helperText={errors.address}
                  InputLabelProps={{
                    shrink: true, // Keeps the label above the field
                  }}
                />
              </AddressAutofill>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Website"
                fullWidth
                value={formData.website}
                onChange={(e) => handleInputChange('website', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                fullWidth
                value={formData.phoneNumber}
                onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Instagram (Optional)"
                fullWidth
                value={formData.instagram}
                onChange={(e) => handleInputChange('instagram', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Twitter (Optional)"
                fullWidth
                value={formData.twitter}
                onChange={(e) => handleInputChange('twitter', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Facebook (Optional)"
                fullWidth
                value={formData.facebook}
                onChange={(e) => handleInputChange('facebook', e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Upload a Photo
            </Typography>
            <Paper
              elevation={2}
              sx={{
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
              }}
            >
              <Avatar
                alt="Uploaded Photo"
                src={formData.photo ? URL.createObjectURL(formData.photo) : undefined}
                sx={{ width: 150, height: 150, margin: 'auto' }}
              />
              <IconButton color="primary" component="label">
                <AddPhotoAlternateIcon />
                <input hidden type="file" onChange={handleFileUpload} />
              </IconButton>
            </Paper>
          </Box>
        );
      default:
        return null;
    }
  };

  if (loading) return <Typography>Loading...</Typography>;

  if (!user) {
    return (
      <div style={{ marginTop: '10vh' }}>
        <Authenticator>
          {({ signOut, user }) => {
            window.location.reload();
          }}
        </Authenticator>
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Become an Outfitter
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 3, mb: 2 }}>{renderStepContent(activeStep)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BecomeAnOutfitter;
