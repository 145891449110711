import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Box,
  Button,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';

function OutfitterDetails({ outfitterDetails }) {
  const navigate = useNavigate();

  const handleChatClick = () => {
    if (outfitterDetails?.userid) {
      navigate(`/chats?chatWith=${outfitterDetails.userid}`);
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <CardContent>
          {/* Header */}
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                src={outfitterDetails?.imageurl}
                alt={`${outfitterDetails.outfitterName} Logo`}
                style={{ width: 80, height: 80 }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                {outfitterDetails.outfitterName}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Owned by {outfitterDetails.ownerName}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ChatIcon />}
                onClick={handleChatClick}
                sx={{ backgroundColor: "#FF8C42" }}
              >
                Chat
              </Button>
            </Grid>
          </Grid>

          <hr />

          {/* About Us Section */}
          <Typography
            variant="subtitle1"
            style={{ marginTop: '1vh', fontWeight: 'bold' }}
          >
            About Us:
          </Typography>
          <Typography
            component="div"
            style={{ textAlign: 'left', marginTop: '1vh', marginBottom: '1vh' }}
          >
            {outfitterDetails.description || 'No description available.'}
          </Typography>

          {/* Contact Info */}
          <Box display="flex" alignItems="center" gap={2} style={{ marginBottom: '1vh' }}>
            <EmailIcon color="action" />
            <Typography variant="body2">{outfitterDetails.email}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2} style={{ marginBottom: '1vh' }}>
            <PhoneIcon color="action" />
            <Typography variant="body2">{outfitterDetails.phoneNumber}</Typography>
          </Box>

          {/* Social Links */}
          <Box display="flex" gap={1} marginTop="1vh">
            {outfitterDetails.instagram && (
              <IconButton
                component="a"
                href={`https://instagram.com/${outfitterDetails.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#E1306C' }}
              >
                <InstagramIcon />
              </IconButton>
            )}
            {outfitterDetails.facebook && (
              <IconButton
                component="a"
                href={`https://facebook.com/${outfitterDetails.facebook}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1877F2' }}
              >
                <FacebookIcon />
              </IconButton>
            )}
            {outfitterDetails.x && (
              <IconButton
                component="a"
                href={`https://twitter.com/${outfitterDetails.x}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1DA1F2' }}
              >
                <TwitterIcon />
              </IconButton>
            )}
            {outfitterDetails.website && (
              <IconButton
                component="a"
                href={`https://${outfitterDetails.website}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#000' }}
              >
                <LanguageIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default OutfitterDetails;
