import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Card,
  CardContent
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OutfitterDetailsCard from '../components/OutfitterDetailsCard';
import { fetchAuthSession } from '@aws-amplify/auth';
import OutingCreationForm from '../components/OutingCreationForm';

const OutfitterDetailsPage = () => {
  const navigate = useNavigate();
  const [outfitterDetails, setOutfitterDetails] = useState(null);
  const [outings, setOutings] = useState([]); // Stores outings
  const [selectedOuting, setSelectedOuting] = useState(null); // Tracks the outing being edited
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [formOpen, setFormOpen] = useState(false); // Controls whether the form is displayed

  useEffect(() => {
    const fetchOutfitterDetails = async () => {
      try {
        const session = await fetchAuthSession();
        if (!session) {
          navigate('/login');
          return;
        }
        const username = session?.tokens?.idToken?.payload['cognito:username'];
        const idToken = session.tokens?.idToken?.toString();
        setUsername(username);
        console.log("ID_TOKEN", idToken);

        // Fetch outfitter details
        const data = await fetch(
          `https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/getters/outfitter_details?username=${username}`
        ).then((res) => res.json());
        setOutfitterDetails(data);

        // Fetch outings
        const outingsData = await fetch(
          `https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/getters/outings?username=${username}`
        ).then((res) => res.json());
        setOutings(outingsData);
      } catch (error) {
        console.error('Error fetching outfitter details or outings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOutfitterDetails();
  }, []);

  const handleEditOuting = async (outing) => {
    const outing_data = await fetch(
      `https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/getters/outingformdata?outingid=${outing.outingid}`
    ).then((res) => res.json());
    setSelectedOuting(outing_data[0]);
    console.log("OUTING_", selectedOuting);
    setFormOpen(true); // Open form in edit mode
  };

  const handleCreateNewOuting = () => {
    setSelectedOuting(null);
    setFormOpen(true); // Open form in new mode
  };

  const handleDeleteOuting = async (outingId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this outing? This action cannot be undone.");
    if (!confirmDelete) return;

    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString();
      const response = await fetch(
        `https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/delete-outing`,
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ outingId }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        alert("Outing deleted successfully!");
        setOutings((prevOutings) => prevOutings.filter((outing) => outing.outingid !== outingId));
      } else {
        alert(result.message || "Failed to delete outing.");
      }
    } catch (error) {
      console.error("Error deleting outing:", error);
      alert("An error occurred while deleting the outing.");
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!outfitterDetails) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h5" color="error">
          Failed to load outfitter details.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      {/* Outfitter Details */}
      {outfitterDetails && <OutfitterDetailsCard outfitter={outfitterDetails[0]} />}

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 4, mb: 2 }}>
        <Typography variant="h5">Your Outings</Typography>
        
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FF8C42", color: "#fff" }}
          onClick={handleCreateNewOuting}
        >
          Create New Outing
        </Button>
      </Box>


      {outings.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>Outing Name</b></TableCell>
                <TableCell><b>Description</b></TableCell>
                <TableCell><b>Max Participants</b></TableCell>
                <TableCell><b>Price</b></TableCell>
                <TableCell><b>Duration</b></TableCell>
                <TableCell><b>Rating</b></TableCell>
                <TableCell><b>Actions</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outings.map((outing) => (
                <TableRow key={outing.outingid}>
                  <TableCell>{outing.name}</TableCell>
                  <TableCell>{outing.description}</TableCell>
                  <TableCell>{outing.maxparticipants}</TableCell>
                  <TableCell>${outing.price}</TableCell>
                  <TableCell>{outing.duration}</TableCell>
                  <TableCell>{outing.rating || 'N/A'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleEditOuting(outing)}
                      sx={{ mr: 1 }}
                    >
                      Edit
                    </Button>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteOuting(outing.outingid)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No outings found.</Typography>
      )}


      {/* Outing Form - Only Show When Editing or Creating */}
      {formOpen && (
        <OutingCreationForm 
          outing={selectedOuting} // ✅ Pass the selected outing
          onClose={() => setFormOpen(false)} 
        />
      )}
          {/* 🔹 JotForm Submission Card */}
          <Card sx={{ mt: 4, maxWidth: 500, mx: "auto", textAlign: "center", p: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Want to be featured?
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Submit your details to be showcased on our platform!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://form.jotform.com/250461140377047"
            target="_blank"
          >
            Submit Showcase
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OutfitterDetailsPage;
