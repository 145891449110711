import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Modal,
  Button,
} from '@mui/material';

// Terms and Conditions Modal Component
export const OutfitterTermsModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          By using this platform, you agree to the following:
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          1. **Legal Responsibility:** As an outfitter, you confirm that you possess all
          necessary permits and licenses to operate your business and the activities
          described.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          2. **Liability:** You acknowledge and accept sole responsibility for the safety
          and well-being of participants in any outing booked through this platform. This
          site solely serves as a facilitator to showcase your offerings.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          3. **Indemnification:** You agree to indemnify and hold this platform and its
          owners harmless from any claims, losses, or damages arising from your activities,
          negligence, or failure to adhere to local laws and regulations.
        </Typography>
        {/* <Typography variant="body2" sx={{ mt: 2 }}>
          4. **No Warranty:** The platform does not guarantee bookings or the success of
          your outings. It merely provides a space to present your business to potential
          customers.
        </Typography> */}
        <Button
          variant="contained"
          sx={{ mt: 3, backgroundColor: '#FF8C42', color: '#fff' }}
          onClick={onClose}
        >
          I Agree
        </Button>
      </Box>
    </Modal>
  );
};


export default OutfitterTermsModal;
