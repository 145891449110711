import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  IconButton,
  Tooltip,
  TextField,
  Avatar,
  Paper
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WebIcon from '@mui/icons-material/Web';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import ChatIcon from '@mui/icons-material/Chat';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Check } from '@mui/icons-material';
import { submitOutfitter } from '../api';
const OutfitterDetailsCard = ({ outfitter }) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(outfitter);
  const [user, setUser] = useState(null);
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSave = async () => {
    if (editMode) {
      submitOutfitter(formData);
    }
    setEditMode(false);
  };

  const colorPalette = {
    background: '#FDF6E3', // Cream
    text: '#6F5B3E', // Brown
    accent: '#FF8C42', // Orange
  };

useEffect(() => {
    const checkUser = async () => {
    try {
    const session = await fetchAuthSession();
    const payload = session?.tokens?.idToken?.payload;
    if (payload['cognito:username']) {
    setUser(payload['cognito:username']);
    } else {
    setUser(null);
    }
    }
    catch (error) {
        console.error('Error fetching user session:', error);
        setUser(null);
    }
    };
    checkUser();
    }, []);


  return (
    <Card
      sx={{
        maxWidth: '90vw',
        margin: 'auto',
        mt: 4,
        boxShadow: 6,
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: colorPalette.background,
        color: colorPalette.text,
      }}
    >
      <Grid container spacing={2}>
        {/* Image Section */}
        <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
          {editMode ? (
            <Paper
              elevation={2}
              sx={{
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: '#fff',
                borderRadius: '8px',
              }}
            >
              <Avatar
                alt="Uploaded Photo"
                src={formData.imageurl || undefined}
                sx={{ width: 150, height: 150, margin: 'auto' }}
              />
              <IconButton color="primary" component="label">
                <AddPhotoAlternateIcon sx={{ color: colorPalette.accent }} />
                <input
                  hidden
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const imageUrl = URL.createObjectURL(file);
                      handleInputChange('imageurl', imageUrl);
                      handleInputChange('photo', file);
                    }
                  }}
                />
              </IconButton>
            </Paper>
          ) : (
            <CardMedia
              component="img"
              image={outfitter?.imageurl}
              alt={outfitter?.name || "Image Not Found"}
              sx={{ height: '100%', maxHeight: 400, objectFit: 'cover', filter: 'brightness(90%)' }}
            />
          )}
          <Box
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 16,
              background: 'rgba(0,0,0,0.6)',
              color: '#fff',
              borderRadius: 2,
              px: 2,
              py: 1,
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {outfitter.outfitterName}
            </Typography>
          </Box>
        </Grid>

        {/* Details Section */}
        <Grid item xs={12} md={8}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {editMode ? 'Edit Outfitter Details' : 'About the Outfitter'}
            </Typography>
            {editMode ? (
              <>
                <TextField
                  fullWidth
                  label="Outfitter Name"
                  value={formData.outfitterName}
                  onChange={(e) => handleInputChange('outfitterName', e.target.value)}
                  sx={{ mb: 2, backgroundColor: '#fff', borderRadius: 1 }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  value={formData.description}
                  onChange={(e) => handleInputChange('description', e.target.value)}
                  sx={{ mb: 2, backgroundColor: '#fff', borderRadius: 1 }}
                />
              </>
            ) : (
              <Typography variant="body1" paragraph>
                {outfitter.description}
              </Typography>
            )}

            <Divider sx={{ my: 2, borderColor: colorPalette.accent }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PersonIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Owner Name"
                      value={formData.ownerName}
                      onChange={(e) => handleInputChange('ownerName', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>Owner:</strong> {outfitter.ownerName}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <EmailIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Email"
                      value={formData.email}
                      onChange={(e) => handleInputChange('email', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>Email:</strong> {outfitter.email}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PhoneIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Phone Number"
                      value={formData.phonenumber}
                      onChange={(e) => handleInputChange('phonenumber', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>Phone:</strong> {outfitter.phonenumber}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <WebIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Website"
                      value={formData.website}
                      onChange={(e) => handleInputChange('website', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>Website:</strong>{' '}
                      <a
                        href={outfitter.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colorPalette.accent, textDecoration: 'none' }}
                      >
                        {outfitter.website}
                      </a>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, borderColor: colorPalette.accent }} />

            <Typography variant="h6" gutterBottom>
              Social Media
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <InstagramIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Instagram"
                      value={formData.instagram}
                      onChange={(e) => handleInputChange('instagram', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>Instagram:</strong> {outfitter.instagram}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <XIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="X"
                      value={formData.x}
                      onChange={(e) => handleInputChange('x', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>X:</strong> {outfitter.x}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <FacebookIcon sx={{ color: colorPalette.accent }} />
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Facebook"
                      value={formData.facebook}
                      onChange={(e) => handleInputChange('facebook', e.target.value)}
                      sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>Facebook:</strong> {outfitter.facebook}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: colorPalette.accent, color: '#fff' }}
              >
                Chat Now
              </Button>
              {user === outfitter.userid && (
                editMode ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setEditMode(false)}
                      sx={{ mr: 2, backgroundColor: '#fff', color: colorPalette.text }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSave}
                      sx={{ backgroundColor: colorPalette.accent, color: '#fff' }}
                    >
                      Save Changes
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => setEditMode(true)}
                    sx={{ backgroundColor: colorPalette.accent, color: '#fff' }}
                  >
                    Edit Details
                  </Button>
                )
              )}
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OutfitterDetailsCard;