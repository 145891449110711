import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  LinearProgress, 
  Grid, 
  TextField, 
  Button 
} from '@mui/material';
import Rating from '@mui/material/Rating';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from '../../api'; // Axios instance for API calls
import { fetchAuthSession } from '@aws-amplify/auth';

function calculatePercentage(count, total) {
  return total > 0 ? Number(((count / total) * 100).toFixed(0)) : 0; // Handles division by zero
}

function calculateStarDistribution(reviews) {
  const starDistribution = {
    5: 0,
    4.5: 0,
    4: 0,
    3.5: 0,
    3: 0,
    2.5: 0,
    2: 0,
    1.5: 0,
    1: 0,
  };

  reviews.forEach((review) => {
    const rating = Math.round(review.rating * 2) / 2; // Round to the nearest 0.5
    if (starDistribution.hasOwnProperty(rating)) {
      starDistribution[rating]++;
    }
  });

  return starDistribution;
}

function RatingComp({ outingid }) {
  const [reviewSummary, setReviewSummary] = useState(null); // Review summary
  const [reviews, setReviews] = useState([]); // Individual reviews
  const [starDistribution, setStarDistribution] = useState({
    5: 0,
    4.5: 0,
    4: 0,
    3.5: 0,
    3: 0,
    2.5: 0,
    2: 0,
    1.5: 0,
    1: 0,
  });

  // State for new review submission
  const [newReview, setNewReview] = useState({
    rating: 0,
    title: '',
    description: ''
  });

  useEffect(() => {
    const fetchReviewSummary = async () => {
      try {
        const response = await axios.get('/outfitter_reviews_summary', { params: { outingid } });
        if (response.data.length > 0) {
          setReviewSummary(response.data[0]); // Assuming API returns an array with one object
        }
      } catch (error) {
        console.error('Error fetching review summary:', error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axios.get('/reviews', { params: { outingid } });
        setReviews(response.data || []); // Fallback to an empty array if no reviews
        const distribution = calculateStarDistribution(response.data || []);
        setStarDistribution(distribution); // Calculate star distribution
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviewSummary();
    fetchReviews();
  }, [outingid]);

  // ✅ Handles form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  // ✅ Handles rating change
  const handleRatingChange = (event, newValue) => {
    setNewReview((prev) => ({ ...prev, rating: newValue }));
  };

  // ✅ Submits the review
  const handleSubmitReview = async () => {
    if (!newReview.rating || !newReview.title || !newReview.description) {
      alert('Please fill in all fields and provide a rating.');
      return;
    }

    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString();

      await fetch(
        `https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/create-review`,
          {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              outingid,
              rating: newReview.rating,
              title: newReview.title,
              description: newReview.description,
              })
          }
        );

      alert('Review submitted successfully!');
      
      // Reset form
      setNewReview({ rating: 0, title: '', description: '' });

      // Refresh reviews
      const response = await axios.get('/reviews', { params: { outingid } });
      setReviews(response.data || []);
      setStarDistribution(calculateStarDistribution(response.data || []));
    } catch (error) {
      console.error('Error submitting review:', error);
      alert('Failed to submit review.');
    }
  };

  return (
    <div>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* Reviews Summary */}
        <Grid item xs={12} md={4}>
          <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
            <Typography variant="h6" gutterBottom>
              Reviews Summary
            </Typography>
            {reviewSummary ? (
              <>
                <Typography gutterBottom>
                  Total Reviews: {reviewSummary.reviewcount || 0}, Average Rating:
                  <Rating name="read-only" value={reviewSummary.avgrating || 0} precision={0.5} readOnly />
                  {reviewSummary.avgrating ? reviewSummary.avgrating.toFixed(1) : 'N/A'} Stars
                </Typography>

                <Accordion sx={{ mt: 2, background: '#f4f4f4', boxShadow: 'none', border: '1px solid #ddd' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle2">Star Distribution</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 2 }}>
                    <Grid container spacing={1}>
                      {Object.keys(starDistribution)
                        .sort((a, b) => b - a) // Sort from highest to lowest
                        .map((star) => (
                          <Grid item xs={12} key={star}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" sx={{ width: 40 }}>{`${star} Stars`}</Typography>
                              <LinearProgress
                                variant="determinate"
                                value={calculatePercentage(starDistribution[star], reviewSummary.reviewcount)}
                                sx={{ flexGrow: 1, mx: 1, height: 10, backgroundColor: '#ddd' }}
                                color="warning"
                              />
                              <Typography variant="body2">{`${starDistribution[star]} Reviews`}</Typography>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <Typography>No review summary available.</Typography>
            )}
          </Box>
        </Grid>

        {/* Customer Reviews */}
        <Grid item xs={12} md={8}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Customer Reviews
            </Typography>
            {reviews.length > 0 ? (
              reviews.map((review, index) => (
                <Box key={index} marginBottom={2}>
                  <Typography variant="subtitle1">{review.title}</Typography>
                  <Typography variant="body2" color="textSecondary">{review.outingName}</Typography>
                  <Rating value={review.rating} readOnly precision={0.5} />
                  <Typography variant="body2">{review.description}</Typography>
                </Box>
              ))
            ) : (
              <Typography>No reviews available.</Typography>
            )}
          </Box>

          {/* Review Submission Form */}
          <Box sx={{ p: 2, mt: 2, border: '1px solid #ddd', borderRadius: 1 }}>
            <Typography variant="h6">Leave a Review</Typography>
            <Rating value={newReview.rating} precision={0.5} onChange={handleRatingChange} />
            <TextField fullWidth label="Title" name="title" value={newReview.title} onChange={handleInputChange} sx={{ mt: 2 }} />
            <TextField fullWidth multiline rows={3} label="Description" name="description" value={newReview.description} onChange={handleInputChange} sx={{ mt: 2 }} />
            <Button variant="contained" sx={{ mt: 2, backgroundColor: "#FF8C42", color: "#fff" }} onClick={handleSubmitReview}>
              Submit Review
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default RatingComp;
