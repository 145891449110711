import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent } from '@mui/material';
import PicSlide from '../components/PicSlide';

function AboutUs() {
  return (
    <Container>
      {/* Title */}
      <Box mb={4}>
        <Typography variant="h3" style={{ textAlign: 'center', color: '#452f0f', fontWeight: 'bold' }}>
          About Us
        </Typography>
      </Box>

      {/* Picture Slideshow */}
      <Box mb={6}  sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <PicSlide
        imageData = { [
            { src: 'https://content.outfitterfinder.com/aboutus/big8.png', index: 1 },
            { src: 'https://content.outfitterfinder.com/aboutus/turkey.png', index: 2 },
            { src: 'https://content.outfitterfinder.com/aboutus/bluehouse.png', index: 3 },
            { src: 'https://content.outfitterfinder.com/aboutus/double.png', index: 4 },
            { src: 'https://content.outfitterfinder.com/aboutus/new8.png', index: 5 },
        ]}
            viwh="50vh"
          viewwid="50vw"
        />
      </Box>

      {/* Message Section */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                style={{ textAlign: 'center', marginBottom: '1rem', fontWeight: 'bold', color: '#453318' }}
              >
                Welcome to OutfitterFinder!
              </Typography>
              <Typography variant="body1" style={{ textAlign: 'justify', color: '#453318', lineHeight: '1.8' }}>
              Thanks for checking out OutfitterFinder! My name is Joe Leva, and as a software engineer with a love for the outdoors,  
              I built this platform to connect people with the activities they’re passionate about.  
              <br />
              <br />
              Growing up, my dad and his best friend had a motto: <strong>Maximize Outdoor Time</strong>.  
              It wasn’t just a phrase—it was a way of life. Whether it was hunting, fishing, or any excuse to be outside,  
              they made sure every free moment was spent doing what they loved. That idea stuck with me,  
              and it’s the foundation of OutfitterFinder.  
              <br />
              <br />
              Life gets busy with work, school, and family, but if you're like me, you're always thinking about the next time  
              you can get back to the woods, the water, or the mountains. That’s why I created this platform:  
              to help people find expert guides, explore new adventures, and support small businesses along the way.  
              I hope OutfitterFinder helps you maximize your outdoor time.  
            </Typography>

              <Typography
                variant="h6"
                style={{ textAlign: 'center', marginTop: '2rem', fontWeight: 'bold', color: '#de792c' }}
              >
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutUs;
