import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Cancelation({ cancellationPolicy }) {
  if (!cancellationPolicy) {
    return (
      <Typography variant="body1" style={{ color: 'gray' }}>
        No cancellation policy available.
      </Typography>
    );
  }

  return (
    <div>
      <Typography
        variant="h6"
        component="div"
        style={{ marginBottom: 10, fontWeight: 'bold', color: 'var(--of-orange)' }}
      >
        Cancellation Policy
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{cancellationPolicy.description || "Cancellation Policy Details"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {cancellationPolicy.map((term, index) => (
              console.log(term, index),
              <ListItem key={index}>
                <ListItemText
                  primary={`${term.refundPercentage}% refundable`}
                  secondary={`if cancelled ${term.daysBefore} days before the trip`}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Cancelation;
