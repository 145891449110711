import React from 'react';
import { Carousel } from 'react-carousel-minimal';

function PicSlide({ imageData, viwh, viewwid }) {
  const viewHeight = viwh || "200px"; // Fixed height for consistent sizing
  const viewWidth = viewwid || "100%";  // Full width for the carousel

  // Prepare the data for the carousel
  const carouselData = (imageData || [])
    
    .filter((item) => item.src) // Ensure valid images are included
    .sort((a, b) => (a.index || 0) - (b.index || 0)) // Sort by index
    .map((item) => ({
      image: item.src,
      caption: "", // No caption to avoid unnecessary placeholders
    }));

  const captionStyle = {
    display: 'none', // Hide captions
  };

  return (
    <div style={{ textAlign: "center" }}>
      {carouselData.length > 0 ? (
        <Carousel
          data={carouselData}
          time={5000}
          width={viewWidth}
          height={viewHeight}
          captionStyle={captionStyle}
          radius="10px"
          slideNumber={false} // Disable slide number
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="cover" // Ensure images fit the carousel properly
          thumbnails={false}
          isIntrinsicHeight={true}
        />
      ) : (
        <div
          style={{
            height: viewHeight,
            width: viewWidth,
            backgroundColor: 'darkgrey',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ color: 'white' }}>No images available</p>
        </div>
      )}
    </div>
  );
}

export default PicSlide;
