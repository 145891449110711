const awsConfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_LxvQLAdow',
  aws_user_pools_web_client_id: 'lcgg0ibbpl8bjdo6dj3cg55l9',
  oauth: {
    domain: 'https://outfitterfinder.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code',
  },
};

export default awsConfig;
