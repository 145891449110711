import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsConfig from '../aws-exports';
import { useNavigate } from 'react-router';
Amplify.configure(awsConfig);

const Login = () => {
    const navigate = useNavigate();
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Login to OutfitterFinder</h1>
      <Authenticator
        loginMechanisms={['email' ]}
        hideSignUp={false}
      >
                {({ signOut, user }) => {
                    // Navigate to the home page and refresh
                    navigate('/');
                    window.location.reload();
                }}
      </Authenticator>
    </div>
  );
};

export default Login;
