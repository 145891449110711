import React, { useState, useEffect } from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
  Popup
} from 'react-map-gl';
import api from '../api'; // Assuming you have an Axios instance for API calls


function SportMap() {
  const [mapBounds, setMapBounds] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [lng, setLng] = useState(-100);
  const [lat, setLat] = useState(40);
  const [zoom, setZoom] = useState(3.5);
  const [selectedMarker, setSelectedMarker] = useState(null); // State to track the selected marker

  const parseGeopoint = (geopoint) => {
    if (!geopoint) return null;
    const coords = geopoint.replace('POINT(', '').replace(')', '').split(' ');
    if (coords.length === 2) {
      const [lng, lat] = coords.map(parseFloat);
      if (!isNaN(lng) && !isNaN(lat)) {
        return { longitude: lng, latitude: lat };
      }
    }
    return null;
  };

  const fetchMapData = async (bbox) => {
    try {
      const response = await api.get('/map', {
        params: { bbox: bbox.join(',') },
      });
      setMarkers(response.data || []); // Ensure markers are always an array
    } catch (error) {
      console.error('Error fetching map data:', error);
    }
  };

  const handleMoveEnd = (event) => {
    try {
      const bounds = event.target.getBounds(); // Correctly fetch the bounds
      const bbox = [
        bounds.getWest(), // minLng
        bounds.getSouth(), // minLat
        bounds.getEast(), // maxLng
        bounds.getNorth(), // maxLat
      ];
      setMapBounds(bbox);
      fetchMapData(bbox);
    } catch (error) {
      console.error('Error handling map move end:', error);
    }
  };

  return (
    <div>
      <Map
        mapboxAccessToken='pk.eyJ1Ijoiam9zZXBobGV2YSIsImEiOiJjbDh4dzAwb3AwYW92M29ud2JrZnBhYTU1In0.pKlCRuU-CQvqyC92ZzU9Ew'
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        style={{
          width: '30vw',
          height: '30vh',
          borderRadius: '15px',
          border: '2px solid #6F5B3E',
        }}
        mapStyle="mapbox://styles/josephleva/cksnefz6959ju17o322s6vgzp"
        onMoveEnd={(event) => handleMoveEnd(event)}
      >
        <NavigationControl position="top-left" />
        <FullscreenControl position="top-left" />
        <GeolocateControl position="top-left" />

        {markers.map((marker) => {
          const coords = parseGeopoint(marker.geopoint);
          if (coords) {
            return (
              <Marker
                key={marker.outingid}
                longitude={coords.longitude}
                latitude={coords.latitude}
                onClick={(e) => {
                  e.originalEvent.stopPropagation(); // Prevent map click event
                  setSelectedMarker(marker);
                }}
              >
                <div
                  style={{
                    backgroundColor: '#6F5B3E', // Marker color (brown)
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  title={marker.name} // Tooltip for accessibility
                ></div>
              </Marker>
            );
          }
          return null; // Skip invalid markers
        })}

      {selectedMarker && (
        <Popup
          longitude={parseGeopoint(selectedMarker.geopoint).longitude}
          latitude={parseGeopoint(selectedMarker.geopoint).latitude}
          onClose={() => setSelectedMarker(null)} // Close popup on click
          closeOnClick={false}
          anchor="top"
        >
          <div
            style={{
              maxWidth: '200px',
              pointerEvents: 'none', // Disable pointer events for the popup
            }}
          >
            <h4 style={{ pointerEvents: 'auto' }}>
              <a
                href={`/outing?outingid=${selectedMarker.outingid}`}
                style={{ textDecoration: 'none', color: '#6F5B3E' }}
              >
                {selectedMarker.name}
              </a>
            </h4>
            <p>{selectedMarker.description}</p>
            <p>
              <strong>Price:</strong> ${selectedMarker.price}
            </p>
            <p>
              <strong>Rating:</strong> {selectedMarker.rating || 'N/A'}
            </p>
            <p>
              <strong>Activities:</strong>{' '}
              {selectedMarker.activities
                ? selectedMarker.activities.map((activity) => activity.name).join(', ')
                : 'N/A'}
            </p>
          </div>
        </Popup>
      )}

      </Map>
    </div>
  );
}

export default SportMap;
