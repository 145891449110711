
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';

const get_url = 'https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/getters/'
const url = "https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod"
const api = axios.create({
  baseURL: get_url , // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});


export const fetchRecentOutings = async () => {
    const response = await api.get('/recent_outings');
    return response.data;
  };


export const fetchCommonLocations = async () => {
    const response = await api.get('/common_locations');
    return response.data;
  };

  export const fetchCommonActivities = async () => {
    const response = await api.get('/common_activities');
    return response.data;
  };

  
export const fetchCategories = async () => {
  const response = await api.get('/categories');
  return response.data;
};

export const fetchSearchValues = async () => {
  const response = await api.get('/search_values');
  return response.data;
};
export const submitOutfitter = async (formData) => {
  try {
    // Step 1: Get the user's session and extract the ID token
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken?.toString();
    // Step 2: Prepare the request payload
    const payload = {
      outfitterName: formData.outfitterName,
      ownerName: formData.ownerName,
      description: formData.description,
      fullAddress: formData.address,
      website: formData.website,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      instagram: formData.instagram,
      x: formData.x,
      facebook: formData.facebook,
    };

    // Step 3: If photo exists, include file details in the payload
    if (formData.photo) {
      payload.fileName = formData.photo.name;
      payload.fileType = formData.photo.type;
    }

    // Step 4: Submit the form data to the API Gateway to get a pre-signed URL (if applicable)
    const response = await axios({
      method: 'post',
      url: "https://c1l91zet7e.execute-api.us-east-1.amazonaws.com/prod/create-outfitter",
      data: payload,
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    });


    // Step 5: If a pre-signed URL is provided, upload the file to S3
    const { uploadUrl } = response.data;
    if (formData.photo && uploadUrl) {
      await axios.put(uploadUrl, formData.photo, {
        headers: {
          'Content-Type': formData.photo.type,
        },
      }).catch((error) => {
        console.error('S3 Upload Error:', error.response || error.message);
        throw error;
      });
    }

    // Step 6: Notify the user of success
    return {
      success: true,
      message: 'Outfitter created successfully!',
    };
  } catch (error) {
    console.error('Error submitting outfitter:', error);
    return {
      success: false,
      message: 'Failed to create outfitter. Please try again.',
      error: error.message,
    };
  }
};


export const submitOuting = async (formData) => {
  try {
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken?.toString();

    // Submit outing details
    const outingData = await submitOutingDetails(formData, idToken);
    console.log('Outing data:', outingData);

    if (outingData.outingId > 0 && formData.images) {
      // Submit images if outing details are successful
      await submitOutingPictures(formData.images, outingData.outingId, idToken);
    }

    return { success: true, message: 'Outing created successfully!' };
  } catch (error) {
    console.error('Error submitting outing:', error);
    return { success: false, message: 'Failed to create outing. Please try again.', error: error.message };
  }
};

export const submitOutingPictures = async (images, outingId, idToken) => {
  if (!images || (!images.sql?.length && !images.upload?.length)) {
    console.log('No images to process.');
    return;
  }

  console.log('Processing outing images:', images);

  try {
    // Request pre-signed URLs
    const { data: { uploadUrls } } = await axios.post(
      `${url}/create-outing-images`,
      {
        outingId: outingId,
        sql: images.sql,
        delete: images.delete,
        upload: images.upload.map(img => ({ name: img.name, type: img.type })),
      },
      {
        headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
      }
    );
    console.log('Pre-signed URLs:', uploadUrls);

    // Upload images in parallel but handle failures
    const uploadResults = await Promise.allSettled(
      images.upload.map((file, index) =>
        axios.put(uploadUrls[index], file, {
          headers: { 'Content-Type': file.type },
        })
      )
    );

    // Log any failed uploads
    uploadResults.forEach((result, index) => {
      if (result.status === "rejected") {
        console.error(`Failed to upload image ${images.upload[index].name}:`, result.reason);
      }
    });

    console.log('Image upload process completed.');
  } catch (error) {
    console.error('Error uploading outing pictures:', error);
    throw error;
  }
};

export const submitOutingDetails = async (formData, idToken) => {
  try {
    // Dynamically construct the payload
    const payload = {
      name: formData.name,
      description: formData.description,
      activity: formData.activity,
      commonLocation: formData.commonLocation,
      exactLocation: formData.exactLocation,
      attributes: formData.attributes,
      cancellationPolicy: formData.cancellationPolicy,
      legalAgreement: formData.legalAgreement,
      maxParticipants: formData.maxParticipants,
      price: formData.price,
      duration: formData.duration,
      inex: formData.inex,
    };
    if (formData.outingid && formData.outingid > 0) {
      payload.outingId = formData.outingid;
    }
    if (formData.category) {
      payload.category = formData.category;
    }

    const response = await axios.post(`${url}/create-outing`, payload, {
      headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
    });

    console.log('Outing details submitted:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting outing details:', error);
    throw error;
  }
};


export default api;
