import React from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function Included({ inclusions = [], exclusions = [] }) {
  return (
    <>
      <Grid container spacing={2}>
        {/* Included Items */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography 
                variant="h6" 
                component="div" 
                style={{ color: 'black', fontWeight: 'bold', marginBottom: 10 }} 
                gutterBottom
              >
                Included in the package
              </Typography>
              <List>
                {inclusions.length > 0 ? (
                  inclusions.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckIcon style={{ color: "var(--of-orange)" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} style={{ color: "var(--of-orange)" }} />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" style={{ color: 'gray' }}>
                    No items included.
                  </Typography>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Excluded Items */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography 
                variant="h6" 
                component="div" 
                style={{ color: 'black', fontWeight: 'bold', marginBottom: 10 }} 
                gutterBottom
              >
                Not included in the package
              </Typography>
              <List>
                {exclusions.length > 0 ? (
                  exclusions.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CloseIcon style={{ color: "var(--of-orange)" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} style={{ color: "var(--of-orange)" }} />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" style={{ color: 'gray' }}>
                    No items excluded.
                  </Typography>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Included;
