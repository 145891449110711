import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Autocomplete from '@mui/material/Autocomplete';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import api from '../api'; // Assuming you have an API utility for Axios calls

export default function TemporaryDrawer({ setFilters }) {
  const [state, setState] = useState({
    left: false,
  });

  const [activities, setActivities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [numPeople, setNumPeople] = useState(null);
  const [maxDuration, setMaxDuration] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  useEffect(() => {
    // Fetch locations and activities on component mount
    const fetchData = async () => {
      try {
        const activitiesResponse = await api.get('/activities');
        const locationsResponse = await api.get('/locations');
        setActivities(activitiesResponse.data);
        setLocations(locationsResponse.data);
      } catch (error) {
        console.error('Error fetching activities or locations:', error);
      }
    };

    fetchData();
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  
  const applyFilters = () => {
    const newFilters = {
      activity_id: selectedActivity?.id || null,
      location: selectedLocation?.id || null,
      duration: maxDuration || null,
      numPeople: numPeople || null,
      maxPrice: maxPrice || null,
    };
  
    setFilters(newFilters);
  
    // Close the drawer
    toggleDrawer('left', false)();
  };
  
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 275 }}
      role="presentation"
      style={{ background: '#F9F6F0', height: '100vh' }}
    >
      <List>
        <ListItem style={{ marginBottom: '1vh' }} disablePadding>
          <h3 style={{ margin: 'auto', color: '#452f0f' }}>Filters:</h3>
        </ListItem>
        <Divider />
        {/* Activity */}
        <ListItem disablePadding>
          <Autocomplete
            id="activityInput"
            options={activities}
            renderInput={(params) => (
              <TextField {...params} label="Activity" variant="filled" />
            )}
            getOptionLabel={(option) => option.name || ''}
            style={{ width: '100%' }}
            value={selectedActivity}
            onChange={(_event, activity) => setSelectedActivity(activity)}
          />
        </ListItem>
        {/* Location */}
        <ListItem disablePadding>
          <Autocomplete
            id="locationInput"
            options={locations}
            renderInput={(params) => (
              <TextField {...params} label="Location" variant="filled" />
            )}
            getOptionLabel={(option) => option.name || ''}
            style={{ width: '100%' }}
            value={selectedLocation}
            onChange={(_event, location) => setSelectedLocation(location)}
          />
        </ListItem>
        {/* Max Duration */}
        <ListItem>
          <h5 style={{ display: 'block' }}>Max Duration (Days):</h5>
          <Slider
            aria-label="maxDuration"
            defaultValue={0}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={10}
            onChange={(e, value) => setMaxDuration(value)}
          />
        </ListItem>
        <Divider />
        {/* Number of People */}
        <ListItem>
          <h5 style={{ display: 'block' }}>Number of People:</h5>
          <Slider
            aria-label="numPeople"
            defaultValue={0}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={10}
            onChange={(e, value) => setNumPeople(value)}
          />
        </ListItem>
        <Divider />
        {/* Max Price */}
        <ListItem>
          <h5 style={{ display: 'block' }}>Max Price:</h5>
          <Slider
            aria-label="maxPrice"
            defaultValue={0}
            valueLabelDisplay="auto"
            step={100}
            marks
            min={0}
            max={10000}
            onChange={(e, value) => setMaxPrice(value)}
          />
        </ListItem>
        <Divider />
        {/* Apply Filters Button */}
        <ListItem>
          <Button
            variant="contained"
            style={{ marginTop: '1vh', width: '100%', background: '#de792c' }}
            onClick={applyFilters}
          >
            Apply Filters
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              style={{ color: '#452f0f' }}
              onClick={toggleDrawer(anchor, true)}
            >
              Filters
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Box>
        </React.Fragment>
      ))}
    </div>
  );
}
